/*--------------------------------------------------------------
21. wpo-contact-pg-section
--------------------------------------------------------------*/
.wpo-contact-pg-section {
  padding-bottom: 0px;

  .wpo-contact-title {
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 35px;
      font-size: calc-rem-value(35);
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        font-size: 25px;
        font-size: calc-rem-value(25);
      }
    }
  }

  .thank-you-container {
    background-color: #fff;
    padding: 50px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    .checkmark {
      font-size: 50px;
      color: #4caf50;
      margin-bottom: 20px;
    }

    h1 {
      margin: 0;
      color: #333;
    }

    p {
      margin-top: 20px;
      color: #777;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      margin-top: 10px;
      color: #555;
    }
  }

  form input,
  form select,
  form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 25px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
      height: 45px;
    }

    &:focus {
      border-color: $theme-primary-color;
      background: transparent;
    }
  }

  form textarea {
    height: 180px;
    padding-top: 15px;
  }

  form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
      display: inline-block;
      color: #a9a9a9;
      cursor: pointer;
      opacity: 1;
      padding: 6px 25px;
      font-size: 15px;
      font-size: calc-rem-value(15);
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
      position: relative;

      &:focus {
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
      }
    }

    .submit-area {
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;

      @include media-query(767px) {
        margin-bottom: 0;
      }

      .theme-btn {
        border-radius: 4px;
        font-family: $base-font;
        font-size: 16px;

        &:after {
          border-radius: 0px;
        }
      }
    }

    .form-field {
      margin-bottom: 30px;
    }

    .required::after {
      content: " *";
      color: red;
    }

    .errorMessage {
      color: red;
      margin-top: 5px;
    }
  }

  form > div {
    margin: 0 15px 25px;

    @include media-query(600px) {
      float: none;
    }
  }

  .office-info {
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-bottom: 60px;
    }

    .col {
      .office-info-item {
        @media (max-width: 1200px) {
          margin-bottom: 30px;
        }
      }

      &:last-child {
        .office-info-item {
          @media (max-width: 1200px) {
            margin-bottom: 0px;
          }
        }
      }
    }

    .office-info-item {
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      padding: 40px;

      .office-info-icon {
        width: 85px;
        height: 85px;
        background: #f5f5f5;
        line-height: 85px;
        text-align: center;
        margin: 0 auto;
        @include rounded-border(50%);

        .icon {
          .fi {
            &:before {
              font-size: 35px;
              line-height: unset;
            }
          }
        }
      }

      .office-info-text {
        min-height: 180px;

        h2 {
          font-size: 30px;
          font-weight: 500;
          margin: 20px 0;
        }

        p {
          margin-bottom: 0;
          cursor: pointer;

          // &:hover {
          // 	background: darken($theme-primary-color, 8%);
          // 	color: $white;
          // }
        }

        a {
          background-image: linear-gradient(
            to right,
            $theme-primary-color,
            $theme-primary-color 50%,
            #000 50%
          );
          background-size: 200% 100%;
          background-position: -100%;
          display: inline-block;
          padding: 5px 0;
          position: relative;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.3s ease-in-out;
        }

        a:before {
          content: "";
          background: $theme-primary-color;
          display: block;
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 0;
          height: 3px;
          transition: all 0.3s ease-in-out;
        }

        a:hover {
          background-position: 0;
        }

        a:hover::before {
          width: 100%;
        }

        .wechatQR {
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s ease-in-out;
          position: absolute;
          top: 100%; // 控制距离 .wechatLink 的距离，100% 通常表示紧接在其下方
          left: 0; // 左对齐
          width: 195px;
          height: auto;
          z-index: 1;
          max-width: none;
        }

        .wechatLink {
          position: relative;

          &:hover {
            .wechatQR {
              opacity: 1;
              display: block;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .wpo-contact-form-area {
    display: grid;
    place-items: center;
    padding: 50px;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    padding-bottom: 40px;
    margin-bottom: -125px;
    position: relative;
    z-index: 99;

    @media (max-width: 767px) {
      padding: 30px;
      padding-top: 50px;
    }
  }
}

.wpo-contact-map-section {
  .wpo-contact-map {
    height: 550px;

    @media (max-width: 767px) {
      height: 350px;
    }

    @media (max-width: 575px) {
      height: 300px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;

    @media (max-width: 767px) {
      height: 350px;
    }

    @media (max-width: 575px) {
      height: 300px;
    }
  }

  iframe:focus {
    outline: none;
  }

  h2.hidden {
    display: none;
  }
}
