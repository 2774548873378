.product {
  font-family: $base-font !important;
  padding-top: 80px;
  padding-bottom: 150px;
  color: #000000;
  font-family: "PT Serif", serif;
  font-size: 20px;
  line-height: 28px;

  @media (min-width: 1900px) {
    .container {
      max-width: 1800px;
    }
  }

  .productCategory {
    .category-mobile-menu {
      margin: 0 30px 30px;
      display: none;
      color: white;
      display: none;
      align-items: center;

      .MuiButtonBase-root {
        background: $theme-primary-color;
      }
    }

    .category-mobile-menu button {
      color: white;
    }

    @media (max-width: 991px) {
      .category-mobile-menu {
        display: block;
      }
    }

    .listItemText {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiListItem-root.categoryItem {
      transition: border-color 0.3s, color 0.3s;
      border: 1px solid black;
      margin-bottom: 10px;

      &:hover {
        border-color: $theme-primary-color;
        color: $theme-primary-color;
        background-color: white;
      }
    }

    .MuiListItem-root.categoryItemSelected {
      transition: background-color 0.3s, color 0.3s;
      background-color: $theme-primary-color;
      color: white;
      border: 1px solid $theme-primary-color;

      &:hover {
        background-color: darken($theme-primary-color, 10%);
        color: white;
      }
    }
  }

  .productContent {
    display: flex;
  }

  .productCategories {
    flex: 2;
  }

  .productList {
    flex: 8;
  }

  .verticalDivider {
    width: 2px;
    /* 控制线宽 */
    background-color: #e3e4e4;
    /* 控制线的颜色 */
    margin: 8px 0;
    /* 控制线的上下边距，使其在容器内稍微短一些 */
  }

  @media (max-width: 991px) {
    .verticalDivider {
      display: none;
    }

    .productContent {
      flex-direction: column;

      .category-list {
        display: none;
      }
    }
  }

  .productTitle {
    position: relative;
    z-index: 1;
    margin-top: 0;
    background-color: inherit;
    font-weight: bold;
    font-size: 46px;
    margin-bottom: 100px;

    @include media-query(991px) {
      font-size: 45px;
      text-align: center;
      margin-bottom: 70px;
    }

    @include media-query(767px) {
      font-size: 40px;
      margin-bottom: 50px;
    }

    @include media-query(550px) {
      line-height: 50px;
    }
  }
}

.wpo-productList-section {
  margin: 0 30px;

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh; /* 根据你的需要调整高度 */
    width: 100%;
  }

  .product-grids {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: minmax(390px, auto);
    gap: 50px;
  }

  .grid-item {
    border: 3px solid rgba(29, 31, 35, 0.15);
    overflow: hidden;
    img {
      transform: scale(1);
      transition: all 0.1s;
      width: auto;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      h3 a {
        color: $theme-primary-color;
      }
    }
  }

  // .grid {
  //   overflow: hidden;
  //   width: calc(50% - 50px);
  //   float: left;
  //   margin: 0 25px 40px;
  //   min-height: 490px;
  //   border: 3px solid rgba(29, 31, 35, 0.15);

  //   @media (min-width: 1900px) {
  //     width: calc(33% - 50px);
  //   }

  //   @include media-query(991px) {
  //     width: calc(50% - 50px);
  //   }

  //   @include media-query(767px) {
  //     width: calc(100%);
  //   }

  //   @include media-query(550px) {
  //     width: calc(100% - 15px);
  //     float: none;
  //   }

  //   img {
  //     transform: scale(1);
  //     transition: all 0.1s;
  //     width: auto;
  //   }

  //   &:hover {
  //     img {
  //       transform: scale(1.05);
  //     }

  //     h3 a {
  //       color: $theme-primary-color;
  //     }
  //   }
  // }

  // .grid:hover .add-to-cart a {
  //   background-color: $theme-primary-color;
  //   color: $white;
  // }

  .details {
    background-color: #f7f7f7;
    text-align: center;
    padding: 35px 15px;
    min-height: 240px;

    h3 {
      font-size: 20px;
      font-size: calc-rem-value(20);
      margin: 0 0 0.5em;
      font-weight: 700;

      @include media-query(991px) {
        font-size: 16px;
        font-size: calc-rem-value(16);
      }
    }

    span {
      font-size: 15px;
      font-size: calc-rem-value(15);
    }

    h3 a {
      color: $heading-color;
    }

    h3 a:hover {
      color: $theme-primary-color;
    }

    del {
      color: $text-color;
    }

    del + span {
      display: inline-block;
      padding-left: 20px;
    }
  }

  .prodcut-detail {
    margin-top: 20px;

    button {
      font-family: $base-font;
      font-size: 15px;
      font-size: calc-rem-value(15);
      font-weight: 600;
      color: $theme-primary-color;
      padding: 12px 20px;
      border: 2px solid $theme-primary-color;
      display: inline-block;
      border-radius: 10px;

      @include media-query(767px) {
        font-size: 12px;
        font-size: calc-rem-value(12);
        padding: 8px 18px;
      }
    }

    button:hover {
      background-color: $theme-primary-color;
      color: $white;
    }

    button i {
      font-size: 15px;
      font-size: calc-rem-value(15);
      display: inline-block;
      padding-left: 5px;
    }
  }
}
