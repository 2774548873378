/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:wght@300;400;500;700;900&display=swap");

/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-ExtraLight.otf') format('opentype');
    font-weight: 200; /* ExtraLight */
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Heavy.otf') format('opentype');
    font-weight: 900; /* Heavy */
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Light.otf') format('opentype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Medium.otf') format('opentype');
    font-weight: 500; /* Medium */
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Normal.otf') format('opentype');
    font-weight: normal; /* Normal */
    font-style: normal;
}

@font-face {
    font-family: 'Source Han Sans';
    src: url('../../fonts/SourceHanSansCN-Regular.otf') format('opentype');
    font-weight: normal; /* Regular */
    font-style: normal;
}

// fonts
$base-font-size: 15;
$base-font: "Roboto", "Source Han Sans", sans-serif;
$heading-font: "PT Serif","Source Han Sans", serif;

// color
$dark-gray: #0a272c;
$dark-gray2: #14212b;
$body-color: #687693;
$white: #fff;
$offWhite: #e9e1e1;
$light: #cbcbcb;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #de3838;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-color: #f3f4f4;
$section-bg-color-s2: #f5f5f5;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
